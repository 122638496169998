import { mainRequests } from '@/helpers/custom'
export default {
  // #region Outlets
  getOutlets () {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/outlets/getOutlets').then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getOutletConfigs (outletname) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/outlets/getOutletConfigs', { outletname }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  saveOutletConfig (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/outlets/saveOutletConfig', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  saveOutletInfo (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/outlets/saveOutletInfo', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // #regionend
  // #regionstart Pods
  getPodList () {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/pods/getList', {}).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getPodConfigs (filter) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/pods/getPods', { outletname: filter.outletname, podcode: filter.podcode }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  savePodConfig (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/pods/savePodConfig', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // #ends
  // #region Schedule
  getScheduleConfigs (outletname) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/schedules/getSchedules', { outletname }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  saveScheduleConfig (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/schedules/saveOutletSchedules', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // #ends
  // #region Banner
  getBanner () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postManager('/banners/getBanner')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createBanner (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/banners/saveBanner', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  editBanner (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/banners/saveBanner', payload).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // #endregion
  // #region Dashboard
  getOrderAndSuggestCount (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getOrderAndSuggestCount', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getShortData (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getShortData', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getKpiCompare (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getKpiCompare', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getSalesWeek (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getSalesWeek', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getSalesHours (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getSalesHours', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getComplaintChart (outlets) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets
      }
      mainRequests.postManager('/dashboard/getComplaintChart', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getComplaints (outlets, type) {
    return new Promise(function (resolve, reject) {
      let body = {
        outlets: outlets,
        type: type
      }
      mainRequests.postManager('/dashboard/getComplaints', body).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getBlackList (body) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/blacklist/listBlacklist', body, {}).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  createBlacklist (body) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/blacklist/createBlacklist', body,{}).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  removeBlacklist (id) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/blacklist/removeBlacklist', { id },{}).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
  // #ends
}
