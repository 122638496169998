<template>
  <div class="dashboard">
    <div class="header" v-loading="loadStats">
      <stat-section v-if="!loadStats" :stats="stats"></stat-section>
      <div v-if="loadStats" style="height: 550px;">
        <div style="position: absolute; top: 50%; left: 50%; transform: translateX(-50%)">
            Уншиж байна...
          </div>
        </div>
    </div>
    <div class="charts">
      <!-- <chart-section></chart-section> -->
    </div>
    <div class="order">
      <el-row :gutter="20">
        <!-- <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane name="first">
              <span slot="label">
                  Хүргэлтийн захиалга<el-badge class="mark" type="primary" :value="count.hur" />
              </span>
              <order-section v-if="activeName=='first'" :orderName="'Хүргэлтийн захиалга'"></order-section>
            </el-tab-pane>
            <el-tab-pane name="second">
              <span slot="label">
                  Ирж авах захиалга<el-badge class="mark" type="primary" :value="count.irj" />
              </span>
              <order-section v-if="activeName=='second'" :orderName="'Ирж авах захиалга'"></order-section>
            </el-tab-pane>
            <el-tab-pane name="third">
              <span slot="label">
                  Урьдчилсан захиалга<el-badge class="mark" type="primary" :value="count.pre" />
              </span>
              <order-section v-if="activeName=='third'" :orderName="'Урьдчилсан захиалга'"></order-section>
            </el-tab-pane>
            <el-button size="medium" type="primary" icon="el-icon-document" class="tableBtn">Дэлгэрэнгүй</el-button>
          </el-tabs>
        </el-col> -->
        <el-col class="complainTable">
          <el-tabs v-model="activeName1" type="border-card">
            <el-tab-pane name="second">
              <span slot="label">
                  Санал<el-badge class="mark" type="primary" :value="count.sanal" />
              </span>
              <complaint-section v-if="activeName1=='second'" :type="'1'"></complaint-section>
            </el-tab-pane>
            <el-tab-pane name="first">
              <span slot="label">
                  Гомдол<el-badge class="mark" type="primary" :value="count.gom" />
              </span>
              <complaint-section v-if="activeName1=='first'" :type="'2'"></complaint-section>
            </el-tab-pane>
            <el-button size="medium" type="primary" icon="el-icon-document" class="tableBtn">Дэлгэрэнгүй</el-button>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import StatSection from '@/components/stats'
import ChartSection from '@/components/charts'
import ComplaintSection from '@/components/complaint'
import managerServices from '@/services/managerServices.js'
import { getGroups } from '@/utils/auth.js'
export default {
  created () {
    getGroups().then(data => {
      const index = data.indexOf('AddressEditor')
      if (index > -1) {
        data.splice(index, 1)
      }
      const index1 = data.indexOf('MenuEditor')
      if (index1 > -1) {
        data.splice(index1, 1)
      }
      this.outlets = data
      // if (!this.outlets) {
      //   location.reload()
      // }
      // managerServices.getOrderAndSuggestCount(this.outlets).then(data => {
      //   if (data.statusCode === 200) {
      //     this.count = data.result
      //   }
      // })
      // managerServices.getShortData(this.outlets).then(data => {
      //   if (data.success) {
      //     this.stats = data.result
      //     this.loadStats = false
      //     this.$forceUpdate()
      //   }
      // })
    })
  },
  components: {
    StatSection,
    // ChartSection,
    // OrderSection,
    ComplaintSection
  },
  data () {
    return {
      outlets: [],
      gomCount: 0,
      gomSanCount: 0,
      count: {},
      loadStats: false,
      activeName: 'first',
      activeName1: 'first',
      stats: [],
      totalToday: {
        labels: ['Хүргэлт', 'Ирж авах', 'Ресторан'],
        datasets: [{
          label: '',
          backgroundColor: [
            '#c6a700',
            '#373737',
            '#c67100'
          ],
          data: [45, 45, 48]
        }]
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss">
  .charts {
    margin: 17px 0;
    .graph-card {
      .el-card__header {
        padding: 10px 20px;
      }
      h3 {
        margin: 0 auto;
        text-align: center;
        color: #fff;
        background: #3a8ee6;
        padding: 5px 17px;
        border-radius: 3px;
        width: fit-content;
        display: block;
        font-weight: 400;
        font-size: 17px;
      }
    }
  }
  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  .order {
    .tableBtn {
    display: block;
    margin: 15px 10px 0px auto;
  }
  }
</style>
